import React from "react";
import File from "../assets/onboard.pdf";

const Onboard = () => {
  return (
    <div className="full-screen">
      <object data={File} type="application/pdf" height="1000vh" width="1000vw">
        {/* <embed src={File}> */}
        <p>
          This browser does not support PDFs. Please download the PDF to view
          it:
          <a href="https://tafoma.mk/onboard.pdf">Download PDF</a>.
        </p>
        {/* </embed> */}
      </object>
    </div>
  );
};

export default Onboard;
